import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/mylesbanner.png`} alt="Profile image of Myles Banner smiling and with cactus behind her" />
      </Link>
      <header>
        <h2>Myles Banner</h2>
        <p><a href="mailto:mylesdbanner@gmail.com">mylesdbanner@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <div>Hi there!</div>
      <div>I am a <a className="boldLink" href="https://github.com/myles-banner" target="_blank" rel="noopener noreferrer">developer</a> and an <a className="boldLink" href="https://dribbble.com/mbanner" target="_blank" rel="noopener noreferrer">artist</a> who focuses on accessibility for digital spaces, product designs, and multiple front-end languages.</div>
      <div>This WCAG nerd (roughly pronounced "wuh-cag") also likes gardening, playing with a variety of tech tools, and designing things.</div>
      <br></br>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="boldLink button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; <Link to="/">mylesdbanner.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
